// Set file variable
$filename: 'scss/custom/_nav.scss';

/*==============================================================================
  @Navigation - #{$filename}
==============================================================================*/

.nav {
  display: none;
  padding: 0.25em 0;
  background: $accent;
  background: var(--bg-nav, var(--accent, $accent));
}

@include media-min('medium') {
  .nav {
    display: block;
  }
}

.nav-menu {
  list-style: none;
  margin: 0;
  display: flex;

  > li {
    flex: 1 0 auto;
    margin: 0 1px 0 0;

    &:last-child {
      margin-right: 0;
    }
  }

  > li > a {
    display: block;
    padding: 0.75em 0.5em;
    color: $white;
    text-align: center;
    border: none;
    border-radius: $border-radius;
  }

  > li > a:hover,
  > li > a:focus,
  > li:hover > a {
    background: rgba($black, 0.1);
    text-decoration: none;
  }

  > li > a:focus {
    outline: none;
  }

  > li.current_page_item > a,
  > li.current_page_parent > a,
  > li.current_page_ancestor > a {
    background: rgba($black, 0.1);
  }
}

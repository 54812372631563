// Set file variable
$filename: 'scss/custom/_featured-action.scss';

/*==============================================================================
  @Featured Action - #{$filename}
==============================================================================*/

/**
 * Structure
 */
.featured-action {
  margin: -1em -1.5em 1em;

  ul {
    list-style: none;
    margin: 0;
  }

  ul li {
    margin: 0;
  }
}

@include media-min('medium') {
  .featured-action {
    margin: 0 0 1em 0;

    ul {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
    }

    ul li {
      display: flex;
      align-items: stretch;
      flex: 1 1 0;
    }

    ul li a {
      flex: 1 1 auto;
    }
  }
}

/**
 * List Items
 */
.featured-action {
  li {
    border-bottom: 1px solid rgba($black, 0.20);
  }

  li:last-child {
    border-bottom: none;
  }
}

@include media-min('medium') {
  .featured-action {
    li {
      border-bottom: none;
      border-right: 1px solid rgba($black, 0.20);
    }

    li:last-child {
      border-right: none;
    }

    li:first-child a {
      @include border-left-radius();
    }

    li:last-child a {
      @include border-right-radius();
    }
  }
}

/**
 * Links
 */

.featured-action a {
  display: block;
  padding: 1em 2em;
  background: $shade;
  background: var(--shade, $shade);
  border: none;
  text-align: center;

  &:hover {
    background: $shade-hover;
    background: var(--shade-hover, $shade-hover);
    text-decoration: none;
  }

  h2,
  p {
    margin: 0;
  }

  h2 {
    font-size: 20px;
    line-height: 1.5em;
    font-weight: normal;
  }

  p {
    font-size: 14px;
    line-height: 1.5em;
    color: $color-light;
  }

  .icon {
    margin: 0;
    font-size: 40px;
    color: $color;
  }

  .content {
    min-width: 200px;
  }
}

@include media-min('medium') {
  .featured-action a {
    padding: 2em;

    .content {
      min-width: 0;
    }
  }
}

/**
 * Layout
 */

@mixin featured-action($layout: 'stack') {
  @if ($layout == 'stack') {
    .featured-action a {
      display: block;
      text-align: center;

      .icon {
        margin-right: 0;
      }
    }
  } @else if ($layout == 'flat') {
    .featured-action a {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: left;

      .icon {
        margin-right: 20px;
      }
    }
  } @else {
    @warn 'The layout style `#{$layout}` does not exist in the `featured-action` mixin.';
  }
}

@include featured-action('stack');

@include media-min(340px) {
  @include featured-action('flat');
}

@include media-min('medium') {
  @include featured-action('stack');
}

@include media-min('large') {
  @include featured-action('flat');
}

// Set file variable
$filename: 'scss/custom/_custom-alt.scss';

/*==============================================================================
  @Logo - #{$filename}
==============================================================================*/

.logo {
  color: $color;

  &:visited {
    color: $color;
  }

  &:hover,
  &:focus {
    color: $color;
  }
}

/**
 * Logo Parts
 */

.logo .member {
  color: #aaa;
}

.logo .sep {
  background: rgba($black, 0.1);
}

/**
 * Vision Source Branding
 */

.logo .vs-logo-sec {
  background-image: url('/fs-shared/dist/img/vs-logo-sec-dark.svg');
}

/*==============================================================================
  @Action - #{$filename}
==============================================================================*/

.action-menu {
  background: $secondary;
  background: var(--secondary, $secondary);
}

.action-menu .button {
  &:hover {
    color: $white;
    background: rgba($white, 0.1);
  }
}

@include media-min('medium') {
  .action-menu {
    background: none;
  }

  .action-menu .button {
    color: $secondary;
    color: var(--secondary, $secondary);
    background: $white;
    border: 1px solid rgba($black, 0.1);

    &:visited {
      color: $secondary;
      color: var(--secondary, $secondary);
    }

    &:hover,
    &:focus {
      color: $secondary;
      color: var(--secondary, $secondary);
      background: $white;
      border-color: $secondary;
      border-color: var(--secondary, $secondary);
    }
  }

  .action-menu .button.text {
    border: 0 none;
  }
}

/*==============================================================================
  @Navigation - #{$filename}
==============================================================================*/

.nav {
  background: $bg-nav;
  background: var(--bg-nav, var(--secondary, $bg-nav));
}

.nav-menu {
  > li > a:hover,
  > li:hover > a,
  > li.current_page_item > a,
  > li.current_page_parent > a,
  > li.current_page_ancestor > a {
    background: rgba($white, 0.1);
  }
}

/*==============================================================================
  @Banner - #{$filename}
==============================================================================*/

.banner::after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  background: rgba($black, 0.1);
}

/*==============================================================================
  @Featured Action - #{$filename}
==============================================================================*/

.featured-action li {
  border-color: rgba($black, 0.1);
}

.featured-action a {
  background: $shade;
  background: var(--shade, $shade);

  &:hover {
    background: $shade-hover;
    background: var(--shade-hover, $shade-hover);
  }

  h2,
  .icon {
    color: $secondary;
    color: var(--secondary, $secondary);
  }
}

/*==============================================================================
  @Buttons - #{$filename}
==============================================================================*/

.button.outline {
  color: $secondary;
  color: var(--secondary, $secondary);
}

/*==============================================================================
  @Widgets - #{$filename}
==============================================================================*/

.widget {
  h2,
  h3,
  h2 a,
  h3 a {
    color: $secondary;
    color: var(--secondary, $secondary);
  }
}

.widget-cta {
  background-color: $shade;
  background-color: var(--shade, $shade);
}

.widget-cta.alt {
  background-color: $white;
}

.widget-cta.alt,
.widget-featured-logos ul {
  border: 1px solid rgba($black, 0.1);
}

/*==============================================================================
  @Main - #{$filename}
==============================================================================*/

.review-rating {
  background: $shade;
  background: var(--shade, $shade);
}

/*==============================================================================
  @Promotions - #{$filename}
==============================================================================*/

.promotions-listing {
  a:hover,
  a:focus {
    background: $shade;
    background: var(--shade, $shade);
  }
}

/*==============================================================================
  @Footer - #{$filename}
==============================================================================*/

.footer {
  background: $bg-footer;
  background: var(--bg-footer, var(--secondary, $bg-footer));
  color: $white;

  h3 {
    color: $white;
  }

  a,
  a:visited,
  a:focus,
  a:hover {
    color: $white;
  }
}

.footer-list {
  li a:hover {
    background: rgba($white, 0.1);
    color: $white;
  }
  .timestamp {
    color: rgba($white, 0.5);
  }
}

/**
 * Logo
 */

.footer-logo {
  .vs-logo-sec {
    background-image: url('/fs-shared/dist/img/vs-logo-sec-light.svg');
  }
}

// Set file variable
$filename: 'scss/custom/_buttons.scss';

/*==============================================================================
  @Buttons - #{$filename}
==============================================================================*/

.button {
  @include make-button();
  @include add-button-color((
    'background-deg' : 2%,
  ));

  // There are cases where we want button text to wrap
  white-space: normal;

  &.block {
    display: block;
  }
}

/**
 * Default
 */

.button.default {
  background: rgba($black, 0.05);

  &:hover {
    background: rgba($black, 0.1);
  }
}

/**
 * Primary
 */

.button.primary {
  color: $white;
  background: $color-button;
  background: var(--color-button, var(--accent, $color-button));

  &:hover {
    color: $white;
    background: $color-button-hover;
    background: var(--color-button-hover, var(--accent-hover, $color-button-hover));
  }
}

/**
 * Outline
 */

.button.outline {
  background: transparent;
  border: 1px solid rgba($black, 0.1);

  &:hover {
    background: transparent;
    border-color: $color-button;
    border-color: var(--color-button, var(--accent, $color-button));
  }

  &:active {
    background: transparent;
    border-color: $color-button;
    border-color: var(--color-button, var(--accent, $color-button));
  }
}
